@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway&family=Roboto+Slab:wght@800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/**All the variable defined to be used is here*/
:root{
    --primary-white: #ffff;
}
/*For everything in the body to follow*/
body{
    font-family: "Roboto Condesed" sans-serif;
}
/***********For all headers***********/

/*************Navbar*******************/
nav{
    z-index: 2;
}
.navbar{
    position: fixed;
    top: 0;
    width: 100%;

}
.bg-info {
    background-color: #3f51b575!important;
}
nav a.nav-link{
    font-weight: 500;
    letter-spacing: 0.0375rem;
    cursor:pointer;
    text-align: center;

}

.logo{
    width:8rem
}

nav ul li{
    text-transform: uppercase;
}

.navbar-nav > li > a{
    color:#ffff !important;
    color:var(--primary-white) !important;
    margin-left: 2rem;
}

.navbar-nav > li > a:active, .navbar-nav > li > a:focus{
    border-top:1px solid greenyellow;
}

.navbar-nav > li > a:hover{
    box-shadow:5px 5px 5px rgb(6, 206, 146);
}
.navbar-toggler:focus,.navbar-toggler:focus,.navbar-toggler:focus{
    outline: none;
    box-shadow: none;
}

.navbar-brand > img{
    border-radius: 70%;
    image-rendering: pixelated;
}

/******************For the header***************/
.header-wraper{
    background:url("https://wallpapercave.com/wp/wp3539903.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
}
@media(min-width:768px) {
    .header-wraper{
        height: 150vh !important;
    }
    .main-info{
        padding-top: 19rem;
    }
}

.main-info{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    position:absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
}

.main-info > h1{
    text-transform: uppercase;
    color:rgb(137, 3, 190);
}

@media(max-width:768px) {
    .main-info h1{
        font-size: 1.5rem !important;
    }
    .typed-text{
    font-size: 1.2rem !important;
    
}
}

.typed-text{
    font-size: 2rem;
    color: rgb(241, 234, 240);
    font-weight: bold;
}

/****For the particles*****/
.style_quotation{
    color: white;
    max-width: 30rem;
}

q {
  quotes: '"' '"' "'" "'";
}
q::before {
  content: open-quote;
}
q::after {
  content: close-quote;
}

.tsparticles-canvas-el{
    position: absolute;
    height: 100vh;
    width: 100vh;
    z-index: 1;
}


/********Timeline*******/
.timeline_container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    
}
 #style_and{
     font-family: cursive;
     font-weight: lighter;
     font-size: medium;
 }

 #style_tasks{
     font-weight: normal;
     font-display:inherit;
     font-family: 'Raleway', sans-serif;
 }

.timeline_container{
    margin: 5rem;
}
#job > .timeline_header{
    margin-top: 5rem;
}

.card_design{
    background-color: red;
}

.timeline_header{
    text-align: center;
    margin: 5rem;
    font-family: 'Roboto Slab', serif;
}
/**********For About**********/
.row{
    margin-top: 5rem;
    margin-bottom: 5rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.about_header{
    font-family: 'Roboto Slab', serif;
}
.row > .column1{
    float: left;
    width: 50%;
    image-rendering: pixelated;
}

.row > .column2{
    float: right;
    width: 50%;
    align-items: center;
    display: flex;
    text-align: center;
    justify-content: center;
    font-family: 'Raleway', sans-serif;
}

.row>h2{
    text-align: center;
    padding-bottom: 2rem;
}

.column1 {
    border-radius: 50%;
    margin-bottom: 3rem;
}

@media(max-width: 768px) {
    .row > .column1{
    width: 100%;
    height: auto;

}

    .row > .column2{
        width: 100%;
    }
}
/***********Cards***********/
.card_container{
    display: flex;
    justify-content:space-around;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 5rem;
    
}
/******Research Container******/
.research_container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    margin: 2rem;
    margin-top: 5rem;
    overflow-x: auto;
    border: 1px solid black;
}

.research_topic{
    margin: 3rem;
    text-align: center;
}
.style_pages_button{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction:column;
}

.style_pages_button> .button1, .style_pages_button> .button2{
    margin: 0.30rem;
    border-radius: 5px;
}
.style_pages_button > .research_pages{
    letter-spacing: 0.5px;
    line-height: 2px;
    font-size: large;
    font-weight: bold;
    font-family: Arial,sans-serif;
}

.style_pages_button > .button1:hover{
    color:aqua;
    background-color: blue;
}
.style_pages_button > .button1:active{
   -webkit-transform:scale(1.1);
           transform:scale(1.1);
   box-shadow: 0 0.2rem 0.2rem 0.2rem rgb(3, 106, 190);
}
.style_pages_button > .button2:hover{
    color:rgb(247, 2, 194);
    background-color: rgb(4, 247, 65);
}
.style_pages_button > .button2:active{
   -webkit-transform:scale(1.1);
           transform:scale(1.1);
   box-shadow: 0 0.2rem 0.2rem 0.2rem rgb(7, 92, 66);
}
/*******************For the contact form****************/
.form-container{
    text-align: center;
    align-items: center;
    border-radius: 5px;
    background-color: #72ddf0;
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5rem;
}

.row{
    margin-bottom: -49px;
}

input, textarea{
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical;
  
}

.col-25{
    float:left;
    width:25%
}

.col-75{
    float:left;
    width:75%;
}

input[type="submit"]{
   background-color: rgb(22, 230, 160); 
}
@media screen and (max-width:600px) {
    .form-container{
        max-width: 100%;
    }
    .col-25,.col-75,input{
        width: 100%;
        margin-top: 0;
        text-align: left;
    }
    .row > input {
        text-align: center;
    }
    label{
        margin-bottom: 5px;
    }
    
}
